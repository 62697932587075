
import { CompanyDashboardApi } from "@/companyDashboardAPI/CompanyDashboardAPI";
import { OrderingRule } from "@/companyDashboardAPI/OrderingRule";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DeleteOrderingRuleDialog extends Vue {
  @Prop({ required: true })
  public readonly ApiClient!: CompanyDashboardApi;

  @Prop({ required: true })
  public showDialog = false;

  @Prop({ required: true })
  public orderingRule!: OrderingRule;

  public isDeleting = false;

  public async deleteOrderingRule(): Promise<void> {
    this.isDeleting = true;
    await this.ApiClient.deleteOrderingRule(this.orderingRule.RuleId).next();
    this.isDeleting = false;
    this.$emit('close', true);
  }

  public closeConfirmation(): void {
    this.isDeleting = false;
    this.$emit('close', false);
  }
}
