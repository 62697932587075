
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { CompanyDashboardApi } from '@/companyDashboardAPI/CompanyDashboardAPI';

import MainCard from '@/components/MainCard.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { OrderingRule } from '@/companyDashboardAPI/OrderingRule';
import DeleteOrderingRuleDialog from '@/components/DeleteOrderingRuleDialog.vue';
import DialogDivider from '../components/DialogDivider.vue';
import OrderingRuleForm from '@/components/OrderingRuleForm.vue';
import { Branch } from '@/companyDashboardAPI/Branch';
import { marked } from 'marked';
import CurrencySymbol from '@/plugins/currencySymbol';

interface BranchName {
  [id: number]: string | null;
}

interface editRule {
  original: OrderingRule;
  CountryText: string;
  BranchText: string;
}

@Component({
  components: {
    MainCard: MainCard,
    LoadingSpinner: LoadingSpinner,
    DeleteOrderingRuleDialog,
    DialogDivider,
    OrderingRuleForm
  }
})
export default class OrderingRulesView extends Vue {
  @Prop({ required: true })
  public readonly ApiClient!: CompanyDashboardApi;

  public loadingText = this.$vuetify.lang.t('$vuetify.loadingPleaseWait');
  public orderingRules: editRule[] = [];

  private branchesNames: BranchName = {};
  public branches: Branch[] = [];
  public confirmDeletion = false;

  public deleteOrderingRule: OrderingRule | null = null;

  public markdown: (src: string) => string = marked;

  public currencySymbol = new CurrencySymbol().getSymbol();

  private async mounted() {
    for await (const branchData of this.ApiClient.getBranches()) {
      this.branches = branchData;
      branchData.forEach((branch) => {
        this.branchesNames[branch.branchId] = branch.branchName;
      });
    }

    await this.initList();

    this.loadingText = '';
  }

  private async initList(): Promise<void> {
    for await (const rules of this.ApiClient.getOrderingRules()) {
      let editRules: editRule[] = [];

      for (const index in rules) {
        editRules.push({
          original: rules[index],
          CountryText: this.getCountryName(rules[index].Country),
          BranchText: this.getBranchName(rules[index].BranchId)
        });
      }

      // Sort by country name, branch name and total price
      editRules.sort((a, b) => {
        if (a.CountryText < b.CountryText) return -1;
        if (a.CountryText > b.CountryText) return 1;

        if (a.BranchText < b.BranchText) return -1;
        if (a.BranchText > b.BranchText) return 1;

        if (a.original.TotalPrice < b.original.TotalPrice) return -1;
        if (a.original.TotalPrice > b.original.TotalPrice) return 1;

        return 0;
      });

      this.orderingRules = editRules;
    }
  }

  public editOrderingRule(orderingRule: OrderingRule): void {
    this.$router.push({
      name: 'OrderingRuleEdit',
      params: {
        orderingRuleData: JSON.stringify(orderingRule)
      }
    });
  }

  public getCountryName(countryCode: string | null): string {
    return countryCode
      ? this.$vuetify.lang.t(`$vuetify.country_${countryCode}`)
      : this.$vuetify.lang.t('$vuetify.orderingRule_allCountries');
  }

  public getBranchName(branchId: number | null): string {
    if (!branchId) {
      return this.$vuetify.lang.t('$vuetify.orderingRule_allBranches');
    }

    return this.branchesNames[branchId] ?? branchId.toString();
  }

  public showDeletionConfirmation(rule: OrderingRule): void {
    this.deleteOrderingRule = rule;
    this.confirmDeletion = true;
  }

  public hideDeletionConfirmation(deleted = false): void {
    this.confirmDeletion = false;
    this.deleteOrderingRule = null;

    if (deleted) {
      this.loadingText = this.$vuetify.lang.t('$vuetify.loadingPleaseWait');
      // Refresh the list with ordering rules.
      this.initList();
      this.loadingText = '';
    }
  }

  public ruleSaved(): void {
    this.loadingText = this.$vuetify.lang.t('$vuetify.loadingPleaseWait');
    this.initList();
    this.loadingText = '';
  }
}
